<template>
  <div class="bg-brand-black/20">
    <div class="absolute inset-x-0 top-0 z-20">
      <slot />
    </div>
    <div class="relative isolate overflow-hidden pt-14 lg:px-10 px-6 lg:min-h-[50rem] flex lg:flex-col justify-center min-h-screen">
      <div v-if="data.media !== 'video'" class="absolute inset-0 bg-black/50 opacity-90 w-full h-full z-[1]" />
      <video
        v-if="data.media === 'video'"
        playsinline="true"
        preload="auto"
        loop="true"
        autoplay
        muted
        class="object-cover absolute left-0 w-full h-full top-0 brightness-50"
      >
        <source
          v-if="data.video.extension === 'webm'"
          type="video/webm"
          :src="data?.video?.url"
        >
        <source
          v-if="data.video.extension === 'mp4'"
          type="video/mp4"
          :src="data?.video?.url"
        >
      </video>

      <BaseImage
        v-else
        :src="data?.image?.original?.src"
        preload
        class="absolute inset-0 -z-10 h-full w-full object-cover"
        alt="Hero background"
        quality="50"
        loading="eager"
      />

      <div class="container py-16 flex flex-col items-center justify-center z-10 text-center h-full relative">
        <div class="h-full flex flex-col items-center justify-between gap-20">
          <div class="flex flex-col items-center justify-center gap-20">
            <BaseTitle
              level="h1"
              variant="white"
              class="leading-normal mt-[10svh] md:mt-0 animate-fade-in"
            >
              <span class="w-fit text-balance" v-html="computedTitle" />
            </BaseTitle>

            <h2
              class="leading-tight text-white font-semibold base-title text-balance w-fit text-lg sm:text-2xl animate-fade-in -mt-16"
            >
              {{ data?.subtitle }}
            </h2>

            <div class="w-full">
              <BaseButtonLink
                variant="primary"
                class="lg:w-fit w-full"
                size="xl"
                :to="localePath(data?.main_button?.button_link)"
                @click="onClickCta"
              >
                {{ data?.main_button?.button_text }}
              </BaseButtonLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import BaseTitle from '~/components/BaseTitle.vue';
import BaseImage from '~/components/BaseImage.vue';
import BaseButtonLink from '~/components/BaseButtonLink.vue';
import { useGTM } from '~/composables';
import { TRACKING_EVENTS } from '~/lib/constants';
import { useLocalePath } from '#imports';

const props = defineProps({
  data: {
    required: true,
    type: Object,
  },
});

const localePath = useLocalePath();

const computedTitle = computed(() => {
  const title = props.data?.title || '';

  return title.replace('|', '<br>');
});

const { triggerEvent } = useGTM();
function onClickCta () {
  triggerEvent(TRACKING_EVENTS.CLICK_HERO_CTA);
}
</script>
